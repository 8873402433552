<template>
<div>
    <div class="wrap">
        <form name="mfrm" @submit.prevent="getReportList" method="post">
            <div class="con_top">
                <h2><span>■</span> 상담일지</h2>
            </div>
            <div class="con">
                <div class="con_table">
                    <select v-model="idxCrmCenter" @change="getReportList()" class="font-16 w-120px h-40px">
                        <option value="-1">센터선택</option>
                        <option value="0">본원센터</option>
                        <option v-for="(item, index) of centerList" :key="index" :value="item.idx">{{item.name}}</option>
                    </select>
                    <select v-model="idxCrmMetaProduct" @change="getReportList()" class="font-16 w-120px h-40px ml-10">
                        <option value="-1">상담구분</option>
                        <option v-for="(item, index) of gubunList" :key="index" :value="item.idx">{{item.product}}</option>
                    </select>

                    <input type="text" v-model="value" class="ml-20 w-200px h-40px pl-20" placeholder="상담사명">
                    <a class="btn_search ml-10 pointer" @click="getReportList()">Search<span></span></a>
                    <table class="default_table mt-20">
                        <tbody v-if="loading">
                            <tr>
                                <th>No</th>
                                <th>상담날짜</th> <!-- 상담날짜 -->
                                <th>센터명</th>
                                <th>상담사명</th>
                                <th>고객명</th>
                                <th>상담구분</th>
                                <th>상담일지 등록여부</th>
                                <th>상담일지 등록일</th>
                                <th>관리</th>
                            </tr>
                            <tr>
                                <td colspan="10">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th>No</th>
                                <th>상담날짜</th> <!-- 상담날짜 -->
                                <th>센터명</th>
                                <th>상담사명</th>
                                <th>고객명</th>
                                <th>상담구분</th>
                                <th>상담일지 등록여부</th>
                                <th>상담일지 등록일</th>
                                <th>관리</th>
                            </tr>
                            <tr v-for="(item, index) of reportList" :key="index">
                                <td>{{item.rownum}}</td>
                                <td>{{dateCheck(item.consDate2)}}</td>
                                <td>{{item.centerName}}</td>
                                <td class="underline pointer font_color" @click="staffInfo(item.idxCrmStaff)">{{item.staffName}}</td>
                                <td class="underline pointer font_color" @click="clientInfo(item.idxCrmClient)">{{item.clientName}}</td>
                                <td>{{item.productName}}</td>
                                <td>{{item.reportYn}}</td>
                                <td>{{dateCheck(item.reportDate)}}</td>
                                <td><span v-if="item.reportYn === '등록'" class="underline pointer" @click="preview(item.clientName, item.consContents, item.idxReservation)">미리보기</span> <span v-if="item.reportYn === '등록'">|</span> <span v-if="item.reportYn === '등록'" class="underline pointer" @click="modify(item.idxReservation)">수정</span></td>
                            </tr>
                            <tr v-if="reportList.length === 0">
                                <td colspan="9">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!-- <preview v-if="onModal" @close="offModal()"></preview> -->
    <preview v-on:permission="putPermission" :onModal.sync="onModal" :clientName.sync="clientName" :consContents.sync="consContents" :idxReport.sync="idxReport" :report.sync="report"></preview>
</div>
</template>

<script>
import Preview from '@/components/admin/confirmConsult/preview.vue'
export default {
    components: {
        Preview
    },
    data: () => ({

        onModal: false, // 미리보기 모달 on/off
        report: {},
        idx: -1,
        type: 'REPORT',
        idxCrmCenter: -1,
        idxCrmMetaProduct: -1,
        productList: [],
        reportList: [],
        centerList: [],
        gubunList: [],
        value: '',
        permission: '',
        checkedReport: [],
        clientName: '',
        consContents: '',
        idxReport: -1,

        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
        genreList:[],
        typeList:[],
        loading:true,
    }),

    mounted() {
        this.getCenterListAll()
        this.getMetaList()

        if(sessionStorage.getItem('admin_consult_pageNum') !== 'undefined' && sessionStorage.getItem('admin_consult_pageNum') !== null) {
            this.pageNum = Number(sessionStorage.getItem('admin_consult_pageNum'))
        }

        if(sessionStorage.getItem('admin_consult_center') !== 'undefined' && sessionStorage.getItem('admin_consult_center') !== null) {
            this.idxCrmCenter = sessionStorage.getItem('admin_consult_center')
        }

        if(sessionStorage.getItem('admin_consult_gubun') !== 'undefined' && sessionStorage.getItem('admin_consult_gubun') !== null) {
            this.idxCrmMetaProduct = sessionStorage.getItem('admin_consult_gubun')
        }

        if(sessionStorage.getItem('admin_consult_value') !== 'undefined' && sessionStorage.getItem('admin_consult_value') !== null) {
            this.value = sessionStorage.getItem('admin_consult_value')
        }

        this.getReportList();
        this.getGenreMeta();/* 회기 형태 리스트 */
        this.getTypeMeta();/*  상담 형태 리스트 */
    },

    methods: {

        dateCheck(date) {
            if (date !== null) {
                return date.slice(0,10)
            } else {
                return '-'
            }
        },

        staffInfo(idx) {
            this.$router.push(`/origin/counselor_manage_card?idx=${idx}`)
        },

        clientInfo(idx) {
            this.$router.push(`/origin/customer_manage_chart?idx=${idx}`)
        },

        // 유입경로, 상담구분, 상담유형 리스트
        getMetaList() {
            this.axios.get('/api/v1/client/meta', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.gubunList = res.data.gubunList
                    }
                })
        },

        getCenterListAll() {

            this.axios.get('/api/v1/center/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList
                    } else {
                        alert('센터리스트 결과가 없습니다.')
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        // 상담일지 미리보기 (모달 on)
        preview(clientName, consContents, idxReservation) {
            this.clientName = clientName;
            if(consContents !== null) {
                if (consContents.length > 100) {
                    consContents = consContents.substr(0, 100) + "..."
                }
            }
            this.consContents = consContents;

            this.axios.get(`/api/v1/cons/report/${idxReservation}`)
            .then((res)=>{
                const { data } = res;
                if(data.err === 0){
                    const selectedGenre = this.genreList.filter(v=> v.idx === data.reportVO.idxGenreMeta);
                    const selectedType = this.typeList.filter(v=> v.idx === data.reportVO.idxTypeMeta);
                    let genreMetaNm ='';
                    let typeMetaNm = '';
                     if(selectedGenre.length > 0){
                        genreMetaNm = selectedGenre[0].cons_title;
                    }else{
                        genreMetaNm = '';
                    }

                    if(selectedType.length > 0){
                        typeMetaNm  = selectedType[0].cons_title;
                    }else{
                        typeMetaNm ='';
                    }

                    const reportObj ={
                        'idxGenreMetaNm' : genreMetaNm,
                        'idxTypeMetaNm' : typeMetaNm,
                        'goal' : data.clientVO.goal,
                        'plan' : data.clientVO.plan,
                        'dangerStepTitle' : data.reportVO.dangerStepTitle,
                        'dangerStepInfo' :data.reportVO.dangerStepInfo,
                        'mainProblem' : data.reportVO.mainProblem,
                        'consProcess' : data.reportVO.consProcess,
                        'consContents' : data.reportVO.consContents,
                        'consOpinion' : data.reportVO.consOpinion,
                        'mentalTest' : data.reportVO.mentalTest,
                        'nextPlan' : data.reportVO.nextPlan,
                        'attachFileUrl' : data.reportVO.attachFileUrl,
                        'attachFileName' : data.reportVO.attachFileName,
                    };

                    this.report = reportObj;
                    this.onModal = !this.onModal;
            
                }else{
                    alert('조회를 실패하였습니다.');
                }
            }).catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            })
        },

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getReportList()
            }
        },
        paging(index) {
            this.pageNum = index
            this.getReportList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getReportList()
            }
        },

        // 상담일지 목록
        getReportList() {
            this.loading = true;
            var params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                value: this.value,
                idxCrmCenter: this.idxCrmCenter,
                idxCrmMetaProduct: this.idxCrmMetaProduct,
                permission: this.permission
            }

            // 검색조건 유지
            sessionStorage.setItem('admin_consult_pageNum', this.pageNum)
            sessionStorage.setItem('admin_consult_center', this.idxCrmCenter)
            sessionStorage.setItem('admin_consult_gubun', this.idxCrmMetaProduct)
            sessionStorage.setItem('admin_consult_value', this.value)

            this.axios.get('/api/v1/cons/report/list', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.reportList = res.data.reportList

                        if (res.data.reportListCount) {
                            this.listLength = res.data.reportListCount
                        }
                        this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                        this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                        this.pagePart = Math.ceil(this.pageNum / 10)
                    } else {
                        //alert('검색 결과가 없습니다.')
                        this.reportList = []
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
                .finally(()=>{
                    this.loading = false;
                })
        },

        // 상담일지 상세내용
        detail(idx) {
            //this.$router.push(`/counselor/journal_info?idx=${idx}`)
            window.open(`/counselor/journal_info?idx=${idx}`, '_blank')
        },

        // 상담일지 등록페이지로 이동
        register(reportYn, idxCrmReservation) {
            if (reportYn !== '등록') {
                this.$router.push(`/counselor/journal_register?idxCrmReservation=${idxCrmReservation}`)
            } else {
                alert('이미 작성한 일지가 있으므로 등록할 수 없습니다.')
            }
        },

        /**
         * 상담일지 수정페이지로 이동
         */
        modify(idxReservation) {
            this.$router.push(`/counselor/journal_modify?idxCrmReservation=${idxReservation}`);
        },

        // 승인
        putPermission(idxReport) {

            this.idx = idxReport

            var params = new URLSearchParams();
            params.append('type', this.type)
            params.append('idx', this.idx)

            this.axios.put('/api/v1/admin/permission', params)
                .then(res => {
                    alert('승인 되었습니다.')
                    this.$router.go(this.$router.currentRoute)
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })

        },

        // 일괄승인
        putPermissionOnce() {

            let params = new URLSearchParams();
            params.append('type', this.type)
            if (this.checkedReport.length > 0) {
                this.idx = ''
                for (var report of this.checkedReport) {
                    this.idx = report.idxReport + "/" + this.idx
                }
            } else {
                alert('선택 한 값이 없습니다.')
                false;
            }
            params.append('idx', this.idx)

            this.axios.put('/api/v1/admin/permission', params)
                .then(res => {
                    alert('승인 되었습니다.')
                    this.$router.go(this.$router.currentRoute)
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        checkedIdx(index) {
            let report = this.reportList[index];
            let isChecked = document.getElementById(index).checked;
            if (isChecked) {
                this.checkedReport.push(report);
            } else {
                let number = this.checkedReport.indexOf(report);
                this.checkedReport.splice(number, 1);
            }
        },

        /**
         * 회기유형 목록
         */
        getGenreMeta(){
            this.axios.get(`/api/v1/cons/report/genre_meta`,{}).then((response)=>{
                this.genreList = response.data.data;
            })
            .catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            });
        },

        /**
         * 상담형태 목록
         */
        getTypeMeta(){
            this.axios.get(`/api/v1/cons/report/type_meta`,{}).then((response)=>{
                this.typeList = response.data.data;
            })
            .catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            });
        },
    }
}
</script>
